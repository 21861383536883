<template>
  <div></div>
</template>

<script>
export default {
  name: "Document Retention Branch File Create",
  created() {
    this.$router.push({
      name: "Document Main Branch",
      params: { _id: this.$route.params._id }
    });
  }
};
</script>
